<template>
  <div>
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <DivisionFilter v-model="filterDivision" />

        <v-btn-toggle class="ml-2" dense v-model="filter" mandatory>
          <v-btn text v-for="f in filters" :key="f.value" :value="f.value"
            >{{ f.label }}
          </v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <v-btn-toggle class="ml-2" dense v-model="selectedTermIndex" mandatory
          ><v-btn text>dieses Semester</v-btn>
          <v-btn text>nächstes Semester</v-btn>
        </v-btn-toggle>
        <v-spacer />
        <ReportButton
          text
          color="danger"
          :parameters="{
            term: term.id,
            divisions: filterDivision[0] == 0 ? [] : filterDivision,
            time: filter,
          }"
          resource="report/events"
          >PDF</ReportButton
        >
        <v-dialog
          width="auto"
          v-model="icalDialog"
          v-if="$root.settings.showIcal"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" text color="info" v-bind="attrs" v-on="on">
              <v-icon left>mdi-calendar-sync</v-icon> iCal
            </v-btn>
          </template>
          <v-card>
            <v-toolbar color="info" dark flat class="mb-4">
              <v-toolbar-title>Synchronisation mit Kalender-App</v-toolbar-title
              ><v-spacer /><v-btn icon @click="icalDialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              ></v-toolbar
            >
            <v-card-text
              >Hier findest du Links um die Termine per iCal-Link in einer
              Kalender-App zu synchronisieren:<br />Dazu einfach den
              entsprechenden Link anklicken und die URL über die Zwischenablage
              in die Kalender-App kopieren.</v-card-text
            >
            <v-list>
              <v-list-item key="icalsync" @click="copyToClipboard(getUrl())">
                <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Alle Abteilungen</v-list-item-title>
                  <pre>{{ getUrl() }}</pre>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="division in divisions"
                :key="'icalsync' + division.id"
                @click="copyToClipboard(getUrl(division))"
                ><v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Abteilung {{ division.code }}</v-list-item-title
                  >
                  <pre>{{ getUrl(division) }}</pre>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-card-text class="pt-4"
              >Alternativ können die iCal-Dateien auch heruntergeladen und
              importiert werden.<br />Nicht empfohlen, da so keine automatischen
              Aktualisierungen möglich sind.</v-card-text
            >
            <v-list>
              <v-list-item
                key="icaldownload"
                @click="apiIcal(isTeacher ? 'event?teacher' : 'event')"
              >
                <v-list-item-icon
                  ><v-icon>mdi-download</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title>Alle Abteilungen</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="division in divisions"
                :key="'icaldownload' + division.id"
                @click="
                  apiIcal(
                    isTeacher
                      ? 'event?teacher&division=' + division.id
                      : 'event?division=' + division.id
                  )
                "
              >
                <v-list-item-icon
                  ><v-icon>mdi-download</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title
                    >Abteilung {{ division.code }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-card :loading="loading">
      <template v-for="(group, key) in itemsGrouped" :value="true">
        <v-system-bar
          :key="'bar' + key"
          :color="key == formatDate(today, true, true) ? 'primary' : ''"
          :dark="key == formatDate(today, true, true)"
        >
          {{ key }}
          {{
            key == formatDate(today, true, true) ? "(heute)" : ""
          }}</v-system-bar
        >
        <v-list :key="'list' + key" dense class="py-0" expand>
          <EventItem v-for="item in group" :key="item.id" :value="item" />
        </v-list>
      </template>
    </v-card>
  </div>
</template>

<script>
import DivisionFilter from "@/components/DivisionFilter";
import EventItem from "common/components/EventItem.vue";
import ReportButton from "@/components/ReportButton";

import { groupBy } from "lodash";
import {
  formatDatespan,
  formatDate,
  today,
  isCurrent,
  isFuture,
  isPast,
} from "common/utils/date.js";
import { copyToClipboard } from "common/utils/helper.js";

export default {
  name: "EventList",
  components: {
    DivisionFilter,
    EventItem,
    ReportButton,
  },
  data() {
    return {
      loading: false,
      icalDialog: false,
      search: "",
      terms: [],
      divisions: [],
      filter: 1,
      filterDivision: [0],
      filters: [
        {
          label: "alle",
          value: 0,
        },
        {
          label: "zukünftige",
          value: 1,
        },

        {
          label: "geänderte",
          value: 2,
        },
      ],
      items: [],
      headers: [
        {
          text: "Datum/Zeit",
          value: "dateTime",
          filterable: false,
        },
        { text: "", value: "icons", sortable: false },
        { text: "Beschreibung", value: "description", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      selectedTermIndex: 0,
    };
  },
  computed: {
    term() {
      if (this.terms.length == 0) {
        return { id: 0 };
      }
      return this.terms[this.selectedTermIndex];
    },
    isTeacher() {
      return this.$_hasRole("teacher");
    },
    itemsGrouped() {
      return groupBy(this.itemsFiltered, (el) =>
        formatDatespan(el.startDate, el.endDate, true, true)
      );
    },
    itemsMapped() {
      return this.items.map((el) => {
        return {
          ...el,
          changed: this.isNew(el),
          current: this.isCurrent(el),
          past: this.isPast(el),
          future: this.isFuture(el),
        };
      });
    },
    today,
    itemsFiltered() {
      return this.itemsMapped.filter((item) => {
        let specialFilter = true;
        switch (this.filter) {
          case 1:
            if (item.past) specialFilter = false;
            break;
          case 2:
            if (item.past || !item.changed) specialFilter = false;
            break;
        }
        return (
          specialFilter &&
          (item.division == null ||
            this.filterDivision.includes(0) ||
            this.filterDivision.includes(item.division.id))
        );
      });
    },
  },
  watch: {
    selectedTermIndex() {
      this.fetchData();
    },
  },
  methods: {
    formatDate,
    copyToClipboard,
    changed() {
      const oldQueryString = this.queryString;
      this.queryString = "groupType=" + this.active.id;
      if (this.queryString !== oldQueryString) {
        this.getData();
        localStorage.setItem("groupType", this.queryString);
      }
    },
    getUrl(division) {
      if (!division) {
        return `${this.backendServer()}api/ical/event${
          this.isTeacher ? "?teacher" : ""
        }`;
      }
      return `${this.backendServer()}api/ical/event?division=${division.id}${
        this.isTeacher ? "&teacher" : ""
      }`;
    },
    isCurrent,
    isFuture,
    isPast,
    isNew(item) {
      const deadline = new Date(this.term.startDate).getTime();
      const value = new Date(item.lastModified).getTime();
      return deadline < value;
    },

    async fetchData() {
      this.loading = true;
      this.divisions = await this.apiList({ resource: "common/division" });
      this.terms = await this.apiList({
        resource: "common/term",
        query: "offsetpast=0&offsetfuture=1",
      });

      this.items = await this.apiList({
        resource: "course/event",
        query: `startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
      this.loading = false;
    },
    customSort(items, sortBy, sortDesc) {
      if (sortBy[0] === "dateTime") {
        items.sort((a, b) => {
          if (!sortDesc[0]) {
            return a.startDate + a.startTime < b.startDate + b.startTime
              ? -1
              : a.startDate + a.startTime > b.startDate + b.startTime
              ? 1
              : 0;
          } else {
            return a.startDate + a.startTime < b.startDate + b.startTime
              ? 1
              : a.startDate + a.startTime > b.startDate + b.startTime
              ? -1
              : 0;
          }
        });
      }
      return items;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
